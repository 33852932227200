<template>
    <app-base>
        <app-layout v-if="viewer !== undefined">
            <slot />
        </app-layout>
    </app-base>
</template>

<script setup lang="ts">
import AppLayout from '~/components/Layouts/ProfileLayout/AppLayout.vue'
import { provideViewer, useViewer } from '~/composables/use-login'
import { useRouter } from 'vue-router'
import { useRouteLink } from '~/composables/routing'
import { ref } from 'vue'
import type { ActiveFacility } from '~/composables/use-active-facility'
import { provideActiveFacility } from '~/composables/use-active-facility'
import AppBase from '~/components/Layouts/AppBase.vue'
import { navigateTo } from '#app/composables/router'
import { useHead } from 'unhead'

const router = useRouter()
await provideViewer()
const viewer = useViewer()
const { orgSignInLink } = useRouteLink()
if (viewer.value === undefined) {
    // Redirect to org login page
    await navigateTo(orgSignInLink(router.currentRoute.value.fullPath))
} else {
    const homeFacility = ref<ActiveFacility>(viewer.value.homeFacility)
    provideActiveFacility(homeFacility)

    useHead({
        style: () => {
            return [
                {
                    innerHTML: homeFacility.value.branding.styleString
                }
            ]
        }
    })
}
</script>
